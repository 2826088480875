.badge {
  margin: .50em;
}

.btn {
  margin: 0em .25em;
}

.card {
  margin: .25em 0em;
}

.on-same-line {
  display: inline-block;
}

td.corner {
	position: relative;
}
td.corner:before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	border-left: 12px solid transparent;
}

td.corner-danger:before {
  border-top: 12px solid #dc3545;
}

td.corner-success:before {
  border-top: 12px solid #28a745;
}

th {
  position: sticky !important;
  top: 0 !important;
  vertical-align: middle !important; 
  background: white !important;
  z-index: 10;
  border-top: 0 !important;
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1450px !important;
  }
}